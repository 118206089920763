import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import { API } from 'aws-amplify';

export default class Roles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keys : {},
            fetchTime: null,
            show : false,
            isLoading : false,
            isError : false,
            getRoleStatus : null
        };
    }

    handleModal = () => {
        this.setState({show:!this.state.show})
    }

    handleLoading = () => {
      this.setState({isLoading:!this.state.isLoading})
    }

    handleError = () => {
      this.setState({isError:!this.state.isError})
    }

    copyToClipboard = elementId => e => {
      var range="";
      var selection="";
      var el = document.getElementById(elementId);
      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(el);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(el);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      document.execCommand('copy');
      if (selection) {
        selection.removeRange(range);
      }
    }

    selectElementText = (el) => {
      var range = document.createRange() // create new range object
      range.selectNodeContents(el) // set range to encompass desired element text
      var selection = window.getSelection() // get Selection object from currently user selected text
      selection.removeAllRanges() // unselect any user selected text (if any)
      selection.addRange(range) // add range to Selection object to select it
    }

    getSelectionText = () => {
      var selectedText = ""
      if (window.getSelection){ // all modern browsers and IE9+
          selectedText = window.getSelection().toString()
      }
      return selectedText
    }

    handleClickMC = async() => {
      const currnetTime = new Date()
      const expireTime = new Date(this.state.fetchTime)
      expireTime.setMinutes ( expireTime.getMinutes() + 14 );
      
      if(currnetTime >= expireTime){
        this.handleLoading();
        let apiName = 'awstvm';
        let path = '/dev/access';

        let myInit = { 
            queryStringParameters: {
              role: this.props.role_name,
              account: this.props.account_id
          }
        }
        await API.get(apiName, path, myInit).then(
          response => {
            this.setState({keys : response});
            this.setState({fetchTime : new Date()});
            this.handleLoading()
            window.open(this.state.keys.singin_url)
        }
        )
      .catch(error => {
          if(error)
            if(error.response)
              if(error.response.status)
                this.setState({getRoleStatus: error.response.status});
          this.handleLoading()
          this.handleError()
          }
        )
    }
    else
    window.open(this.state.keys.singin_url)
  }

    handleClickCLI = async() => {

      const currnetTime = new Date()
      const expireTime = new Date(this.state.fetchTime)
      expireTime.setMinutes ( expireTime.getMinutes() + 14 );

      if(currnetTime >= expireTime){
          this.handleLoading()
          let apiName = 'awstvm';
          let path = '/dev/access';

          let myInit = { 
              queryStringParameters: {  // OPTIONAL
                role: this.props.role_name,
                account: this.props.account_id
            }
          }
          await API.get(apiName, path, myInit).then(
            response => {
              this.setState({keys : response});
              this.setState({fetchTime : new Date()});
              this.handleLoading()
              this.handleModal()
          }
          )
        .catch(error => {
            this.setState({getRoleStatus: error.response.status});
            this.handleLoading()
            this.handleError()
            }
          )
      }
      else
        this.handleModal()
    }

    render() {
        const {role_name, account_id} = this.props;
        const {getRoleStatus} = this.state
        let getRoleError = ""
        if( getRoleStatus === null || getRoleStatus === 200 )
          getRoleError = ""
        else if(getRoleStatus === 403)
          getRoleError="You don't have access to the role " + role_name + " in the account" + account_id + " or tvm is not able to assume the role. Please contact your administrator"        
        else if(getRoleStatus === 500)
          getRoleError="Server error while trying to get the access.Please try again later"
        else
          getRoleError="Something went wrong. Please contact the administrator"

        return (
            <div key={role_name}>
                 {role_name}	<Button variant="link" onClick={this.handleClickMC}>Management console</Button>|<Button variant="link" onClick={this.handleClickCLI}>Command line or programmatic access</Button>

                 <Modal
                  show={this.state.isLoading}
                  onHide={this.handleLoading}
                  size="sm"
                  dialogClassName="modal-5w"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  >
                 <Modal.Body>
                 <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                 </Modal.Body>
                 </Modal>

                 <Modal
                  show={this.state.isError}
                  onHide={this.handleError}
                  size="sm"
                  // dialogClassName="modal-5w"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  >
                  <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  <h4>ERROR</h4>
                  </Modal.Title>
                  </Modal.Header>
                 <Modal.Body>
                 <Alert variant="warning">
                  <Alert.Heading>{getRoleStatus}</Alert.Heading>
                  <hr />
                  <p>
                    {getRoleError}
                  </p>
                  <hr />
                  </Alert>
                 </Modal.Body>
                 </Modal>

                <Modal
                  show={this.state.show}
                  onHide={this.handleModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      <h4>AWS CLI/Programmatic Access</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h3>AWS account: {account_id} Role: {role_name}</h3>
                    <p><strong id="duration">Credentials are valid for 1 hour.</strong></p>
                    <h4>Option 1: Adding a profile to your AWS credentials file</h4>
                    <p>Paste the following text in your AWS Credentials file (typically found at ~/.aws/credentials).</p>
                    <pre><code id="credentialsFileContent">
                    [{account_id}-{role_name}]
                      <br/>
                      aws_access_key_id={this.state.keys.aws_access_key_id}
                      <br/>
                      aws_secret_access_key={this.state.keys.aws_secret_access_key}
                      <br/>
                      aws_session_token={this.state.keys.aws_session_token}
                    </code></pre>
                    <Button onClick={this.copyToClipboard("credentialsFileContent")}>Copy</Button>
                    <h4>Option 2: Set AWS environment variables </h4>
                    <p>Paste the following text in the command prompt to set the AWS environment variables.</p>
                    <Tabs defaultActiveKey="macOSandLinux" id="cliapikeytab">
                    <Tab eventKey="macOSandLinux" title="macOS and Linux">
                      <br/>
                      <pre><code id="macOSandLinuxContent">
                        export AWS_ACCESS_KEY_ID={this.state.keys.aws_access_key_id}
                        <br/>
                        export AWS_SECRET_ACCESS_KEY={this.state.keys.aws_secret_access_key}
                        <br/>
                        export AWS_SESSION_TOKEN={this.state.keys.aws_session_token}
                      </code></pre>
                      <Button onClick={this.copyToClipboard("macOSandLinuxContent")}>Copy</Button>
                    </Tab>
                    <Tab eventKey="WindowsCMD" title="Windows CMD">
                    <br/>
                      <pre><code id="WindowsCMDContent">
                        set AWS_ACCESS_KEY_ID={this.state.keys.aws_access_key_id}
                        <br/>
                        set AWS_SECRET_ACCESS_KEY=this.state.keys.aws_secret_access_key}
                        <br/>
                        set AWS_SESSION_TOKEN={this.state.keys.aws_session_token}
                      </code></pre>
                      <Button onClick={this.copyToClipboard("WindowsCMDContent")}>Copy</Button> 
                    </Tab>
                    <Tab eventKey="WindowsPowershell" title="Windows Powershell">
                    <br/>
                      <pre><code id="WindowsPowershellContent">
                        $env:AWS_ACCESS_KEY_ID={this.state.keys.aws_access_key_id}
                        <br/>
                        $env:AWS_SECRET_ACCESS_KEY={this.state.keys.aws_secret_access_key}
                        <br/>
                        $env:AWS_SESSION_TOKEN={this.state.keys.aws_session_token}
                      </code></pre>
                      <Button onClick={this.copyToClipboard("WindowsPowershellContent")}>Copy</Button> 
                    </Tab>
                    <Tab eventKey="AWSserviceclient" title="AWS service client">
                      <br/>
                      AWS_ACCESS_KEY_ID <pre><code id="access_key_id">{this.state.keys.aws_access_key_id}</code></pre> 
                      <Button size="sm" onClick={this.copyToClipboard("access_key_id")}>Copy</Button> 
                      <br/>
                      <br/>
                      AWS_SECRET_ACCESS_KEY
                      <pre><code id="secret_key_id">{this.state.keys.aws_secret_access_key}</code></pre>
                      <Button size="sm"  onClick={this.copyToClipboard("secret_key_id")}>Copy</Button> 
                      <br/>
                      <br/>
                      AWS_SESSION_TOKEN
                      <pre><code id="session_token">{this.state.keys.aws_session_token}</code></pre>
                      <Button size="sm"  onClick={this.copyToClipboard("session_token")}>Copy</Button>
                      <br/>
                    </Tab>
                  </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleModal}>Close</Button>
                  </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

