import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Alert from 'react-bootstrap/Alert'
import Search from './assets/search-icon.png'

import 'bootstrap/dist/css/bootstrap.min.css';

// import Accounts from './components/Accounts';
import Header from './components/Header';
import Account from './components/Account';
import ErrorBoundary from './components/ErrorBoundary'
import './App.css';
import '@aws-amplify/ui/dist/style.css';

import Amplify, { Auth, Hub, API } from 'aws-amplify';
import { withOAuth } from 'aws-amplify-react';
import amplifyConfig from './amplifyConfig'

Amplify.configure(amplifyConfig)

class App extends Component {

  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.getRoles = this.getRoles.bind(this);

    // let the Hub module listen on Auth events
    Hub.listen('auth', data => {
        switch (data.payload.event) {
            case 'signIn':
                this.setState({authState: 'signedIn', authData: data.payload.data});
                this.getuserinfo()
                break;
            case 'signIn_failure':
                this.setState({authState: 'signIn', authData: null, authError: data.payload.data});
                break;
            default:
                break;
        }
    });
    this.state = {
      authState: 'loading',
      authData: null,
      authError: null,
      user: null,
      email: null,
      accounts: [],
      getAccountStatus: null,
      searchText: ""
    }
  }

  componentDidMount() {
    this.getuserinfo()
  }

  getuserinfo = () => {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({user : user});
      this.setState({email : user.attributes.email});
      this.setState({authState: 'signedIn'});
      this.getRoles()
    }).catch(e => {
      setTimeout(() => {
        if (this.state.authState === 'loading') {
          this.setState({authState: 'signIn'});
        }
      }, 500)
    });
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn'});
    }).catch(e => {
      console.log(e);
    });
  }

  async getRoles () {
    let apiName = 'awstvm';
    let path = '/dev/roles';
    await API.get(apiName, path).then(
      response => {
        this.setState({accounts : response.accounts});
        this.setState({getAccountStatus: 200});
        if(this.state.accounts === null)
          this.setState({accounts : []});
    }
    )
    .catch(error => {
        if(error)
          if(error.response)
            if(error.response.status)
              this.setState({getAccountStatus: error.response.status});
        }
      )
  }

  handleSearchInput = event => {
    this.setState({
      searchText: event.target.value
    });
  };

  render() {
    const { authState, email, accounts, searchText, getAccountStatus } = this.state;
    let filteredAccounts=""
    let getAccountError=""

    if(getAccountStatus === null)
        filteredAccounts=""
    else if(getAccountStatus === 200)
    {
       filteredAccounts = accounts.filter((data)=>{
        if(searchText === null)
            return data
        else if(data.account_id.toLowerCase().includes(searchText.toLowerCase()) || data.account_name.toLowerCase().includes(searchText.toLowerCase())){
            return data
        }
      }).map(data=>{
        return(
            <Account account={(data)} key={data.account_id}/>
        )
      })
    }
    else if(getAccountStatus === 403)
    {
      getAccountError="You don't have access to any of the roles in any of the account. Please raise the UAR request to get the needed access"
      // filteredAccounts=<h1>You don't have access to any of the roles in any of the account. Please raise the UAR request to get the needed access</h1>
    }

    else if(getAccountStatus === 500)
    {
      getAccountError="Server error while fetching account and role information. Please try again later"
      // filteredAccounts=<h1>Server error while fetching account and role information. Please try again later</h1>
    }
    else
    {
      getAccountError="Something went wrong. Please contact the administrator"
      // filteredAccounts=<h1>Something went wrong. Please contact the administrator</h1>
    }

    if(getAccountError !== "")
    {
      filteredAccounts= 
      <div>
      <br />
      <Alert variant="warning">
      <Alert.Heading>{getAccountStatus}</Alert.Heading>
      <hr />
      <p>
        {getAccountError}
      </p>
      <hr />
      </Alert>
      </div>
    }

    return (
      <div className="App">
        {authState === 'loading' &&
        (<div>
          loading...
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>)
        }

        {authState === 'signIn' && this.props.OAuthSignIn({customProvider: 'SiemensMyID'})}
        {authState === 'signedIn' &&
          <Container>
              <Header user={email} signOut={this.signOut} />
            <ErrorBoundary>
              <Form inline>
              <FormControl
                onChange={this.handleSearchInput}
                value={searchText}
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                // width=90%
              />
              <Form.Label><img src={Search} alt=""></img></Form.Label>
              </Form>
            </ErrorBoundary>

            <ErrorBoundary >
              {filteredAccounts}
            </ErrorBoundary>

          </Container>

        }
      </div>
    );
  }
}

export default withOAuth(App)
